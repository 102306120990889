.hero {
  position: relative;
  background: url('../images/fon-main.jpg'); 
  background-size: cover;
}

.heroContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 140px 50px 40px 50px;
  max-width: 1550px;
  margin: 0 auto 0 auto;
}

.imageContainer {
  position: relative;
  width: 40%;
  order: 2;
  display: flex;
  justify-content: center;
  align-items: center; 
}

.heroImage {
  position: relative;
  max-width: 100%;
  height: auto;
  width: 100%;
  max-width: 500px;
  margin: auto;
  z-index: 2;
  padding: 20px;
}

.textContent {
  width: 60%;
  text-align: left;
  order: 1;
}

.textContentBlock {
  position: relative;
  max-width: 80%;
  padding: 10px 20px;
  margin-bottom: 7px;
  z-index: 1;
}

.textContentBlock::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5); /* Полупрозрачный фон */
  backdrop-filter: blur(5px); /* Блюр эффект */
  z-index: -1; /* Помещаем фон за контент */
  border-radius: 20px; /* Скругление углов, если необходимо */
}

.textContent h1 {
  color: #333;
  color: #111;
  margin-bottom: 35px;
  text-shadow: 
    0px 0px 12px #fff, /* Легкий размытие для мягкой обводки */
    0px 0px 15px #fff, /* Дополнительное размытие для большего эффекта */
    0px 0px 18px #fff; /* Можно добавить больше теней для усиления эффекта */
}

.textContent p {
  color: #333;
  color: #111;
  padding: 10px 0 10px 0;
}

.highlight {
  color: var(--primary-color);
  font-weight: 800;
}

@media (max-width: 768px) {
  .heroContent {
    flex-direction: column;
    text-align: center;
    padding: 20px;
    margin: 65px auto 0 auto;
  }
  .imageContainer, .textContent {
    width: 100%;
    order: initial;
    text-align: center;
  }
  .textContent h1 {
    padding: 20px 2% 0 2%;
  }
  .textContent p {
    padding: 10px 1% 10px 1%;
  }
  .heroImage {
    max-width: 90%;
  }
  .textContentBlock {
    max-width: 100%;
  }
}
