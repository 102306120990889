.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  background: #FFF;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: top 0.3s;
  z-index: 1000;
}

.visible {
  top: 0;
}

.hidden {
  top: -100px;
}

.logo {
  width: auto;
  height: 50px;
}

.contactInfo {
  display: flex;
  align-items: center;
  gap: 20px;
}

.socialIcon {
  width: 30px;
  height: 30px;
}

.phoneNumber {
  color: #26ad50;
  font-weight: bold;
  font-size: 21px;
  text-decoration: none;
}

@media (max-width: 768px) {
  .logo {
    height: 40px;
  }
  .phoneNumber {
    font-size: 18px;
  }
}
