.feature {
  display: flex;
  align-items: center;
  padding: 80px 60px;
  max-width: 1550px;
  margin: auto;
}

.featureImage {
  max-width: 600px;
  max-height: 600px;
  width: 100%;
  height: auto;
  object-fit: contain;
}

.textContent {
  flex: 1;
  padding: 0 20px;
}

.imageContainer {
  flex: 1;
  text-align: center;
}

.imageContainer, .textContentContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 50%;
}

.buttonContainer {
  text-align: center;
  max-width: 600px;
  margin: 0 auto; 
}

.textContent {
  max-width: 600px;
}

.textContent h2 {
  margin-bottom: 30px;
}

.imageLeft {
  flex-direction: row;
}

.imageRight {
  flex-direction: row-reverse;
}

@media (max-width: 768px) {
  .feature {
    flex-direction: column;
    padding: 50px 30px;
  }
  .imageContainer, .textContentContainer {
    width: 100%;
    max-width: none;
    justify-content: center;
  }
  .textContent {
    max-width: none;
    padding: 0;
  }
  .featureImage {
    max-width: 350px;
    max-height: 350px;
    margin: 50px 0 30px 0;
  }
  .textContent h2 {
    text-align: center;
    padding: 25px 0 1px 0;
  }
}
