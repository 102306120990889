.reviews {
  padding: 30px 0 40px 0;
}

.reviewsTitle {
  text-align: center;
  margin: 40px 0 50px 0;
}

.reviewItem {
  text-align: center;
  padding: 20px;
}

.reviewImage {
  width: 220px;
  height: 220px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto 20px;
}

.reviewText {
  margin-bottom: 20px;
  color: #333;
  max-width: 700px;
  margin: auto;
  padding-bottom: 30px;
}

.reviewAuthor,
.reviewAuthor:hover,
.reviewAuthor:active {
  font-weight: bold;
  color: #000;
  padding-bottom: 10px;
  text-decoration: none;
}

