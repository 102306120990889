@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --font-size-title: calc(1.25rem + 0.8vw);
  --font-size-text: calc(0.9rem + 0.5vw);
  --font-size-button: calc(1rem + 0.2vw);
  --line-height-title: 1.3;
  --line-height-text: 1.6;
  --spacing-section: 80px 50px;
  --spacing-section-mobile: 50px 20px;
  --spacing-element: 20px;
  --letter-spacing-title: 0.5px;
  --primary-color: #26ad50;
  --primary-color-hover: #37be61;
  --secondary-color: #3390ec;
  --secondary-color-hover: #2B7CDA;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: "Fira Sans Condensed", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
}

h1, h2 {
  font-size: var(--font-size-title);
  line-height: var(--line-height-title);
  letter-spacing: var(--letter-spacing-title);
  margin-bottom: var(--spacing-element);
}

h1 {
  font-size: calc(3rem + 0.8vw);
  line-height: 1.1;
}

p {
  font-size: var(--font-size-text);
  line-height: var(--line-height-text);
}

.primaryButton, .secondaryButton {
  font-size: var(--font-size-button);
  padding: 15px 25px;
  border-radius: 50px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
  display: inline-block;
  position: relative;
  max-width: 600px;
  margin: 40px 0 10px 0;
  min-width: 240px;
  font-weight: 500;
  text-align: center;
}

.primaryButton {
  background-color: var(--primary-color);
  color: white;
  border: none;
}

.primaryButton:hover, .primaryButton:focus {
  background-color: var(--primary-color-hover);
}

.secondaryButton {
  background-color: none;
  color: var(--primary-color);
  border: var(--primary-color);
}

.secondaryButton:hover, .secondaryButton:focus {
  color: var(--primary-color-hover);
}

.App {
  color: #4F4F4F;
  background-color: #FFF;
}

main {
}

@media (max-width: 768px) {
  h1 {
    font-size: calc(2rem + 0.8vw);
  }
}
