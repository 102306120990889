.whyChooseUs {
  padding: 80px 50px 120px 50px;
  text-align: center;
  max-width: 1550px;
  margin: auto;
}

.title {
  margin-bottom: 110px;
}

.benefits {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 100px;
}

.benefitItem {
  flex: 0 1 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.icon {
  width: 50px;
  height: 50px;
  color: var(--primary-color); 
  margin-bottom: 15px;
}

.benefitItem p {
  max-width: 480px;
}

@media (max-width: 768px) {
  .benefitItem {
    flex-basis: 100%;
    margin-bottom: 30px;
  }
  .benefits {
    gap: 30px;
  }
  .title {
    margin-bottom: 60px;
  }
  .whyChooseUs {
    padding: 50px;
  }
  .icon {
    width: 35px;
    height: 35px;
  }
  .whyChooseUs {
    padding: 20px 50px 50px 50px;
  }
}
