.footer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 50px 0;
  text-align: center;
  width: 100%;
  border-radius: 25px;
  margin: 0 auto;
}

.channelLink:hover {
  color: var(--primary-color-hover);
}

.channelLink {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0 10px 0;
  text-decoration: none;
  font-size: calc(0.8rem + 0.5vw);;
  color: #000;
}

.textContent {
  position: absolute;
  bottom: 0;
  left: 0;
  padding-bottom: 5px;
  width: 100%;
}

.footerImg {
  width: 100%;
  height: 250px;
  background: url('../images/fon-sochi.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top center;
}

@media (max-width: 768px) {
  .footerImg {
    height: 150px;
  }
}
